<template>
  <div class="play-back">
    <div class="play-main" v-if="playbackVideos">
      <div class="play_video">
        <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :options="playerOptions"
          :playsinline="true"
          @ready="playerReadied"
        >
        </video-player>
        <div class="schedule_video">
          <div class="left" @click="handePre" v-show="index !== 0"><a-icon type="left" /></div>
          <div class="video_main" ref="video_main">
            <div class="video_list" :style="{ marginLeft: marginOffset + 'px' }">
              <div class="videoimg" v-for="(item, index) in playbackVideos" :key="index">
                <video :class="{ chooseEffect: item.url === videoUrl }" :src="item.url" @click="toVideo(item)"></video>
              </div>
            </div>
          </div>
          <div
            class="right"
            @click="handeNext"
            v-show="index !== maxNumber && !(playbackVideos.length <= Math.floor(this.video_main / 200))"
          >
            <a-icon type="right" />
          </div>
        </div>
      </div>
    </div>
    <div class="schedule-info" v-if="scheduleData.courseSectionRespDTO">
      <div class="info">
        <div>
          <p class="schedule-title">
            <span>
              {{
                scheduleData.courseSectionRespDTO.data.level3
                  ? '第' + scheduleData.courseSectionRespDTO.data.level3 + '课- '
                  : ''
              }}
              {{ scheduleData.scheduleName }}
            </span>
          </p>
          <img
            class="completed"
            v-if="scheduleData.scheduleStatus === 'COMPLETED'"
            src="@/assets/icons/service_pic_finished.svg"
            alt=""
          />
          <p class="schedule-text">
            <span class="schedule-label">时间：</span>
            <span class="schedule-infos timezone_name">
              <span
                >{{
                  moment(scheduleData.startDateTime).format('YY/MM/DD') === moment(new Date()).format('YY/MM/DD')
                    ? moment(scheduleData.startDateTime).format('YY/MM/DD') + '(今天)'
                    : moment(scheduleData.startDateTime).format('YY/MM/DD') + ' '
                }}
                {{ moment(scheduleData.startDateTime).format('dddd') }}
                {{ scheduleData.startDateTime.substring(11, 16) }}-{{ scheduleData.endDateTime.substring(11, 16) }}
              </span>
              <span>{{ scheduleData.timezoneName ? scheduleData.timezoneName.label : '' }}</span>
            </span>
          </p>
          <p class="schedule-text">
            <span class="schedule-label">时长：</span
            ><span class="schedule-infos">{{ scheduleData.courseSectionRespDTO.course.standardDuration }}分钟</span>
          </p>
          <p class="schedule-text">
            <span class="schedule-label">状态：</span
            ><span class="schedule-infos">{{ scheduleData.scheduleStatus === 'COMPLETED' ? '已结束' : '待开课' }}</span>
          </p>
          <p class="schedule-text">
            <span class="schedule-label">进度：</span
            ><span class="schedule-infos">
              <span v-if="scheduleData.courseSectionRespDTO.data.level1">
                {{ scheduleData.courseSectionRespDTO.data.level1 }}
              </span>
              <span v-if="scheduleData.courseSectionRespDTO.data.level2">
                - {{ scheduleData.courseSectionRespDTO.data.level2 }}
              </span>
              <span v-if="scheduleData.courseSectionRespDTO.data.level3">
                - {{ scheduleData.courseSectionRespDTO.data.level3 }}
              </span></span
            >
          </p>
          <p class="schedule-text">
            <span class="schedule-label">教师：</span
            ><span class="schedule-infos">{{ scheduleData.teacher.fullName }}</span>
          </p>
          <p class="schedule-text">
            <span class="schedule-label">班级：</span
            ><span class="schedule-infos">{{ scheduleData.formClassName }}</span>
          </p>
          <p class="schedule-text">
            <span class="schedule-label">类型：</span
            ><span class="schedule-infos">{{ scheduleData.classType === 'PRIVATE' ? '私教课' : '班课' }}</span>
          </p>
        </div>
        <!-- <div class="more" @click="toCourseDetails">
          <span>更多信息</span>
        </div> -->
        <p class="studnet-title">上课学生（{{ scheduleData.students.length }}）</p>
        <div class="students-info">
          <div class="students-images" v-for="(item, index) in showAllStudents" :key="index">
            <img :src="item.headImageUrL || require('@/assets/service_pic_head.png')" alt="" />
            <span>{{ item.name }}</span>
            <span v-if="item.identity" class="identity">{{
              item.identity === 'NORMAL' ? '' : item.identity === 'TRANSFER' ? '插班生' : '旁听生'
            }}</span>
          </div>
          <p v-if="scheduleData.students.length > 6" @click="showAllStudentInfo" style="color: #999">
            剩余{{ scheduleData.students.length - 6 }}
            <a-icon :type="showAllStudents.length > 6 ? 'caret-up' : 'caret-down'" />
          </p>
        </div>
      </div>
    </div>
    <div class="loading" v-if="pageLoading">
      <a-spin size="large" />
    </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import moment from 'moment'
import storage from 'store'
// import sessionStorage from 'store/storages/sessionStorage';
import { videoPlayer } from 'vue-video-player'

// const session = storage.createStore(sessionStorage);
import { playbackCourse, queryCourseSchedule, queryTimezones } from '@/api/headTeacher'
export default {
  components: {
    videoPlayer
  },
  data() {
    return {
      // token: this.$route.query.token,
      scheduleData: {},
      showAllStudents: [],
      playbackVideos: null,
      playerOptions: {
        height: '100%',
        autoplay: false,
        muted: true,
        language: 'zh-CN',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: ''
          }
        ],
        aspectRatio: '16:9',
        poster: '',
        notSupportedMessage: '无法找到此课节视频源'
      },
      index: 0,
      maxNumber: 0,
      offset: 200,
      marginOffset: 0,
      video_main: 0,
      videoUrl: '',
      pageLoading: true
    }
  },
  created() {
    this.initschedule()
  },
  mounted() {},
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    moment,
    toCourseDetails() {
      this.$router.push({ path: 'courseDetails', query: { uuid: this.$route.query.uuid } })
    },
    showAllStudentInfo() {
      if (this.showAllStudents.length > 6) {
        this.showAllStudents = this.scheduleData.students.slice(0, 6)
      } else {
        this.showAllStudents = this.scheduleData.students
      }
    },
    initTimeZero() {
      queryTimezones().then(res => {
        const timeZero = res.data.content
        this.scheduleData.timezoneName = timeZero.find(item => {
          return item.value === this.scheduleData.formClassTimeZone
        })
      })
    },
    handePre() {
      if (this.index === 0) {
        return
      }
      this.index -= 1
      this.play(-this.index)
    },
    handeNext() {
      if (this.index === this.maxNumber) {
        return
      }
      this.index += 1
      this.play(-this.index)
    },
    play(index) {
      this.marginOffset = 240 * index
    },
    toVideo(data) {
      this.videoUrl = data.url
      this.playerOptions.sources[0].src = data.url
    },
    initschedule() {
      const params = {
        uuid: this.$route.query.uuid
      }
      queryCourseSchedule(params).then(res => {
        this.scheduleData = res.data.content
        this.showAllStudents = this.scheduleData.students.slice(0, 6)
        this.initTimeZero()
      //  this.initVideo(res.data.content.liveUrl)
      })
       this.initVideo()
    },
    initVideo(url) {
      const params = {
       // liveUrl: url
       scheduleId:this.$route.query.uuid
      }
      playbackCourse(params).then(res => {
        this.playbackVideos = res.data.playbackVideos
        if (res.data.playbackVideos.length === 0) {
          this.pageLoading = false
        } else {
          this.playerOptions.sources[0].src = res.data.playbackVideos[0].url
          this.videoUrl = res.data.playbackVideos[0].url
        }
      })
    },
    // player is ready
    playerReadied() {
      this.video_main = this.$refs.video_main.clientWidth
      this.maxNumber = this.playbackVideos.length - Math.floor(this.video_main / 200)
      this.pageLoading = false
      document.getElementsByClassName('schedule-info')[0].style.alignItems = 'center'
      document.getElementsByClassName('info')[0].style.height = `${document.getElementsByClassName('play_video')[0]
        .clientHeight - 40}px`
    }
  }
}
</script>

<style lang="less">
.play-back {
  .gray {
    color: #999;
  }
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f7f7f7;
  height: calc(100vh);
  .info {
    width: 100%;
  }
  .play-main {
    // width:  calc(100vw - 570px );
    height: calc(100vh - 40px);
    width: 70%;
    background-color: #fff;
    border-radius: 40px 0px 0px 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    // align-items: center;
    overflow-y: auto;
    // padding: 20px 0 0 0;
    .play_video {
      width: 100%;
      padding: 20px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-wrap: wrap;
      // flex-direction: column;
      // overflow-y: auto;
    }
    .vjs-custom-skin {
      width: 100%;
      .vjs-tech {
        border-radius: 30px;
      }
      .video-js {
        background-color: transparent !important;
      }
      .vjs-big-play-button {
        width: 100px;
        height: 100px !important;
        line-height: 100px !important;
        border-radius: 50%;
        font-size: 7em;
        margin-left: -0.5em;
        background-color: rgba(255, 255, 255, 0.5);
        border: none;
        .vjs-icon-placeholder:before {
          color: #009cff;
        }
      }
      .vjs-control-bar {
        border-radius: 0 0 30px 30px !important;
      }
    }
    .chooseEffect {
      border-radius: 10px;
      border: 2px solid #009cff;
    }
    .schedule_video {
      width: 100%;
      padding: 20px 0 0;
      display: flex;
      justify-content: flex-start;
      position: relative;
      .left {
        width: 20px;
        height: 108px;
        font-size: 14px;
        line-height: 108px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        position: absolute;
        z-index: 2;
        left: 0;
        &:hover {
          background-color: #009cff;
          color: #fff;
        }
      }
      .right {
        width: 20px;
        height: 108px;
        font-size: 14px;
        line-height: 108px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
        position: absolute;
        z-index: 2;
        right: 0;
        &:hover {
          background-color: #009cff;
          color: #fff;
        }
      }
      .video_main {
        width: 100%;
        margin: auto;
        overflow: hidden;
      }
      .video_list {
        display: flex;
        justify-content: flex-start;
        transition: all 0.5s linear;
      }
      .videoimg {
        width: 192px;
        height: 108px;
        margin-right: 20px;
        border-radius: 8px;
        video {
          width: 192px;
          height: 108px;
          border-radius: 8px;
        }
      }
    }
  }
  .schedule-info {
    width: 30%;
    padding: 0 20px;
    background-color: #fff;
    border-radius: 0px 40px 40px 0px;
    display: flex;
    align-items: center;
    overflow-y: auto;
    .schedule-level {
      display: inline-block;
      height: 24px;
      font-size: 14px;
      line-height: 24px;
      padding: 0 5px;
      text-align: center;
      color: #ffbf00;
      background: rgba(255, 191, 0, 0.1);
      border-radius: 6px;
    }
  }
  .schedule-title {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
  }
  .schedule-text {
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    color: #333;
    line-height: 16px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    .schedule-infos {
      display: inline-block;
      width: 75%;
    }
    .timezone_name {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .studnet-title {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin-top: 22px;
  }
  .completed {
    float: right;
    margin-top: -55px;
    margin-right: 20px;
  }
  .students-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 68%;
    overflow-y: auto;
    .students-images {
      width: 50%;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
      span {
        font-size: 16px;
        font-weight: 600;
        color: #333;
        margin: 0 0 0 6px;
      }
      .identity {
        font-size: 14px;
        color: #009cff;
      }
    }
  }
  .more {
    width: 100%;
    span {
      display: inline-block;
      padding: 6px 24px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 10px;
      border: 1px solid #999999;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .loading {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20% 0 0 0;
    text-align: center;
    font-size: 60px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
}
</style>
